<template>
  <div v-if="isInError" :class="{ 'd-inline': referenceKey != null }">
    <span
      v-if="referenceKey"
      v-b-tooltip.hover
      :aria-label="$t('components.tei.listWarning', [referenceKey])"
      :title="$t('components.tei.listWarning', [referenceKey])"
      class="text-warning ml-2"
    >
      {{ referenceKey }}
    </span>
    <p v-else class="text-warning">
      {{
        $t("components.tei.wordCountWarning", [
          wordCountPrimary,
          wordCountReference
        ])
      }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TeiWordCountWarning",
  props: {
    teiPrimary: {
      type: String,
      default: ""
    },
    /**
     * Reference tei text, to which the number of words (<w>) should correspond
     */
    teiReference: {
      type: String,
      default: ""
    },

    referenceKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    isInError() {
      return this.wordCountPrimary !== this.wordCountReference;
    },
    wordCountPrimary() {
      return countWordElements(this.teiPrimary);
    },
    wordCountReference() {
      return countWordElements(this.teiReference);
    }
  },
  methods: {}
};
function countWordElements(teiText) {
  return (teiText.match(/<[\s]*w[\s]*>/g) || []).length;
}
</script>
